const Cards = [

    {
        link: "2019",
        img1: "st191",
        img2: "st192",
    },

    {
        link: "2018",
        img1: "st181",
        img2: "st182",
    },
    {
        link: "2017",
        img1: "st171",
        img2: "st172",
    },



    {
        link: "2016",
        img1: "st161",
        img2: "st162",
    },
    {
        link: "2015",
        img1: "st151",
        img2: "st152",
    },

]


export default Cards;