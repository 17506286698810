import * as React from 'react'
import 'react-html5video/dist/styles.css';
import "../../style/studio/studio.scss"
import Layout from "../defaults/layout"
import SEO from "../defaults/seo"
import { Link } from "gatsby"
import ReactPlayer from 'react-player'

const back = require("../../images/studio/backArrow.png")

const StudioLayout = ({ children, year, video, url }) =>
{

    React.useEffect(() =>
    {
        window.document.body.style.overflow = "auto"
    })
    return (<Layout>
        <SEO title="Studio" keywords={[`Navasardi`, `Arsen Navasardi`, `Navasardi Art Studio`, `Navasardi Art Studio Years`, `Art Studio Yerevan`, `Art Studio Armenia`, `Navasardi Studio Album`,]} />
        <div id="studio" className="studio-card-content">
            {
                //     (video ? <ReactPlayer
                //     height="100%"
                //     width="100%"
                //     playing={true}
                //     loop={true}
                //     muted={true}
                //     controls={true}
                //     url={url}
                // />: 
                (!year ?
                    <ReactPlayer
                        height="100%"
                        width="100%"
                        playing={true}
                        loop={true}
                        muted={true}
                        controls={true}
                        url="https://res.cloudinary.com/dmgjq19hi/video/upload/v1567266295/final_rddnpc.mp4"
                    /> : null)
            }
            <div className="flex-between studio-head container">
                <div>
                    <h1>Navasardi <br /> studio <span>{year}</span></h1>
                    {year && <div className="backLink">
                        <Link to="/studio/"><img src={back} alt="" />{" Back"}</Link>
                    </div>}
                </div>
                <div className="butterfly" />
                <div className="studio-head-link">
                    <p>Navasardi center of aesthetics is a private art studio founded by Arsen Navasardi in Yerevan, Armenia in 2014. Our principal mission is to educate our students and the public in the creation and most importantly appreciation of works of art.
                            <br /><br />
                        We value freedom of mind and critical thinking, that enable people to make new discoveries and create innovations. So we try to instill in our students initiative, diligence, and discipline to be creators, not just followers.
                              <br /><br />
                        We value practical experience of an artist, so we provide students with all the necessary materials that can inspire, motivate and help them in the process of creation.
                              <br /><br />
                        We value our studio environment that advances taste, principles of social equity and inclusion.
                    </p>
                </div>
            </div>
            {children}
        </div>
    </Layout>)
}

export default StudioLayout