import React from 'react'
import cardData from "./studioData"
import StudioCard from "./studioCard"
import ReactPlayer from 'react-player';

function StudyYears()
{
    const videos = ["https://res.cloudinary.com/dmgjq19hi/video/upload/v1567268112/2017_xit9ov.mp4", "https://res.cloudinary.com/dmgjq19hi/video/upload/v1567268152/2015_xxoseu.mp4"]
    return (
        <div className="studio-1-body">
            {/* <div style={{ marginTop: "30px", padding: "0 140px" }}> */}
            <div className="studioVideos">
                {videos.map((el) =>
                    <div className="video-item">
                        <ReactPlayer
                            key={el}
                            height="100%"
                            width="100%"
                            playing={true}
                            loop={true}
                            muted={true}
                            controls={true}
                            url={el}
                        />
                    </div>)
                }
            </div>

            {/* </div> */}
            <div className="studio-1 container">
                <div className="absImg absImg1"><span className="absImg absImg1-normal" /> <span className="absImg absImg1-hover" /></div>
                <div className="absImg absImg2"><span className="absImg absImg2-normal" /> <span className="absImg absImg2-hover" /></div>
                <div className="absImg absImg3"><span className="absImg absImg3-normal" /> <span className="absImg absImg3-hover" /></div>
                <div className="absImg absImg4"><span className="absImg absImg4-normal" /> <span className="absImg absImg4-hover" /></div>
                <div className="flex" >
                    {cardData.map((item, index) =>
                        <StudioCard key={index} data={item} />
                    )}
                </div>
            </div>
        </div>
    );
}

export default StudyYears;