import React from "react"
import StudioYears from "../components/studio/studyYears"
import StudioLayout from "../components/studio/studioLayout";
import "../style/studio/studio.scss"

function StudioPage() {

    return (
        <StudioLayout>
            <StudioYears />
        </StudioLayout>
    )
}

export default StudioPage
