import React from 'react';
import { Link } from "gatsby"
import Image from "../defaults/image"

const StudioCard = (props) =>
{

    const { link, img1, img2 } = props.data

    return (
        <Link className="studio-anchor" to={`/studio/year-${link}/`}>
            <div className="studio-cards-main">
                <div className="studio-cards"></div>
                <div className="hover-img"><Image image={img1} /></div>
                <div className="hover-img2"><Image image={img2} /></div>
                <span>{link}</span>
            </div>
        </Link>
    );
}


export default StudioCard